<template>
  <div>
    <bread-crumb></bread-crumb>
    <section class="blog-info" v-if="iinformations">
      <div
        class="blog-info__descr"
        v-if="data"
        v-html="data.ii_description"
      ></div>

      <h2 class="blog-info__title" v-if="data">{{ data.ii_title }}</h2>

      <div class="blog-info__wrap">
        <div
          class="blog-info__item"
          v-for="item in iinformations"
          :key="item.id"
        >
          <div class="blog-info__img" v-if="item.image">
            <v-lazy-image
              :src="url + item.image"
              rel="preload"
              width="300"
              height="300"
              alt=""
            />
          </div>
          <div class="blog-info__item--descr" v-html="item.description"></div>
        </div>

        <div class="blog-info__link">
          <button class="blog-info__all" @click="getMoreInterInformations()">
            {{ t("load_more") }}
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const BreadCrumb = defineAsyncComponent(() =>
  import("@/components/BreadCrumb")
);
import VLazyImage from "v-lazy-image";
import axios from "axios";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useMeta } from "vue-meta";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    useMeta({
      title: t("BlogInfo"),
      htmlAttrs: {
        lang: localStorage.getItem("VueAppLanguage"),
        amp: true,
      },
    });
    return {
      t,
    };
  },
  components: {
    BreadCrumb,
    VLazyImage,
  },
  props: {
    url: {
      type: String,
      default: process.env.VUE_APP_API,
    },
  },
  data() {
    return {
      locale: this.$i18n.locale,
      iinformations: [],
      data: [],
      page: 1,
    };
  },
  methods: {
    async getInterInforamations() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API +
            "/api/" +
            this.locale +
            "/inter-informations",
          config
        );
        this.iinformations = response.data.data;
      } catch (e) {
        alert("Ошибка запроса");
      } finally {
        //что-то делаем после завершения
      }
    },
    async getMoreInterInformations() {
      try {
        this.page += 1;
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            page: this.page,
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API +
            "/api/" +
            this.locale +
            "/inter-informations",
          config
        );
        this.iinformations = [...this.iinformations, ...response.data.data];
      } catch (e) {
        alert("Ошибка");
      }
    },
    async getBlogPage() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API + "/api/" + this.locale + "/blog-page",
          config
        );
        this.data = response.data.data;
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
  },
  created() {
    this.getBlogPage();
    this.getInterInforamations();
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.blog-info {
  &__title {
    text-align: center;
    font-weight: 700;
    @include property("font-size", 42, 34);
    line-height: 1;
    color: #000000;
    text-transform: uppercase;
    @include property("margin-top", 40, 20);
    @include property("margin-bottom", 60, 30);
  }

  &__descr {
    max-width: 800px;
    margin: 0 auto 1em;
    line-height: calc(25 / 16);

    pre {
      @media (max-width: 575.98px) {
        white-space: initial;
      }
    }
  }

  &__wrap {
    display: grid;
    gap: 80px 110px;
    @include property("column-gap", 110, 40, true, 1670, 1024);
    @include property("row-gap", 80, 25);
    margin-right: 20px;
    @include property("margin-bottom", 100, 30);

    @media (min-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__item {
    position: relative;
    display: grid;

    @media (max-width: 1023.98px) {
      max-width: 600px;
      margin: 0 auto;
    }

    @media (min-width: 576px) {
      grid-template-columns: 1fr 1fr;
    }

    &::before {
      content: "";
      position: absolute;
      top: -20px;
      left: 20px;
      width: 100%;
      height: 100%;
      border: 1px solid #f9b80e;
      z-index: 2;

      @media (max-width: 575.98px) {
        top: -15px;
        left: 15px;
      }
    }

    &--descr {
      display: flex;
      align-items: center;
      padding: 25px 20px;
      color: #1c1c1c;
      font-size: 18px;
      line-height: calc(30 / 18);

      @media (max-width: 575.98px) {
        padding: 15px 15px 25px 30px;
      }

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__link {
    display: flex;
    justify-content: flex-end;
    @include property("margin-top", -50, -10);

    @media (min-width: 1024px) {
      grid-column: 2 span;
    }
  }

  &__all {
    font-size: 1rem;
    line-height: calc(30 / 16);
    letter-spacing: 2.4px;
    color: #f9b80e;
  }

  &__img {
    position: relative;
    align-self: flex-start;
    overflow: hidden;
    max-height: 300px;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.news {
  display: grid;
  @include property("grid-column-gap", 150, 40, true, 1366, 1024);
  @include property("grid-row-gap", 60, 20);
  @include property("margin-bottom", 100, 30);

  @media (min-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }

  &__date {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 3px solid #f9b80e;
    letter-spacing: 0.8px;
    color: #f9b80e;
  }

  &__descr {
    line-height: calc(25 / 16);
    letter-spacing: 0.8px;
    color: #1c1c1c;
    margin: 0;
  }

  &__wrap {
    display: flex;
    justify-content: flex-end;
    @include property("margin-top", -30, -10);

    @media (min-width: 576px) {
      grid-column: 2 span;
    }
  }

  &__all {
    align-self: flex-end;
    line-height: calc(30 / 16);
    letter-spacing: 2.4px;
    color: #f9b80e;
  }
}
</style>
